const version = '1';

/** LOGIN * */
export const SIGN_IN = `${version}/auth/ValidateAccess`;
export const URL_SENSIDIA =
  'https://api.vli-logistica.com.br/oauth/access-token';

/* CONTRACTS */
export const CONTRACTS = `${version}/contract`;
export const CONTRACTS_CONTROL_REPORT = `${version}/Contract/report`;
export const CONTRACTS_CONTROL_REPORT_DOWNLOAD = `${version}/Contract/report/download`;

/* CONTRACT DETAIL  */
export const CONTRACT_DETAILS = (id) => `${version}/contract?ContractId=${id}`;
export const CONTRACT_RAILWAY_ITEMS = (listingParameter) =>
  `${version}/railwayitem/contract/details?ContractId=${listingParameter}`;
export const CONTRACT_EXTEND = `${version}/Contract/extend`;

/** RAILWAY ITEMS HISTORY * */
export const RAILWAY_ITEMS_HISTORY = `${version}/RailwayItem/history`;

/** DIESEL READJUSTMENT * */
export const GET_SUPPLIERS = (listingParameter) =>
  `${version}/supplier/details?${listingParameter}`;
export const DIESEL_READJUSTMENT = `${version}/Diesel`;
export const DIESEL_READJUSTMENT_VLI = `${version}/Diesel/vlisuppliers`;
export const DIESEL_READJUSTMENT_UPDATE = `${version}/Diesel`;
/** INFLATIONARY INDICES * */
export const GET_INFLATIONARY_INDICES = `${version}/inflationindicator`;
export const SAVE_INFLATIONARY_INDICES = `${version}/InflationIndicatorReadjustment`;

/** INDICES HISTORY * */
export const SUPPLIERS = (listingParameter) =>
  `${version}/supplier/filter?${listingParameter}`;
export const INDICES_HISTORY = `${version}/IndexReport`;
export const INDICES_HISTORY_REPORT = `${version}/IndexReport/report`;

/** SPREADSHEET IMPORT * */
export const SPREADSHEET_IMPORT = `${version}/Contract/importSpreadsheet`;
/**  ACCOUNT MANAGER * */
export const ACCOUNT_MANAGER = `${version}/AccountManager`;

/**  CUSTOMER * */
export const CUSTOMER = `${version}/Customer`;
export const CUSTOMER_PROPOSALS = `${version}/Customer/proposals`;

/** REPORT CONTRACT */

export const REPORT_CONTRACT = `${CONTRACTS}/reports`;
export const CALCULATED_PRICE = `${version}/CalculatedValueApproval`;

export const DOWNLOAD_LETTER = `${version}/DownloadDieselLetter`;

export const DOWNLOAD_SPREADSHEET = `${version}/Contract/download/spreadsheet`;

export const GET_CURVED_FARE = `${version}/RailwayItem/curved-fare`;
export const HEALTH = `health`;
export const GET_PRIVILEGES_SCHEMA = `${version}/Profile/initial-structure`;
export const RESOURCE_PROFILE = `${version}/Profile`;
export const RESOURCE_USER = `${version}/User`;
export const RESOURCE_SEGMENT = `${version}/Segment`;
export const RESOURCE_EMAIL_NOTIFICATION_CLIENT = `${version}/Contract/recipients`;
export const RESOURCE_EMAIL_NOTIFICATION_MANAGER = `${version}/Local/recipients`;
export const RESOURCE_REPORT_CONTRACT_ITEMS = `${version}/ContractRailwayItemSelection`;
export const RESOURCE_CONTRACT_REPORT = `${version}/ContractReport`;
export const RESOURCE_SUPPLIER = `${version}/Supplier`;
export const RESOURCE_RAILWAYITEM = `${version}/Railwayitem`;
export const RESOURCE_RESENDITEMS = `${version}/railwayitem/history/resend/paged`;
export const RESOURCE_REACTIVATEITEMS = `${version}/InactivatedRailwayItem/paged`;
export const RESOURCE_REACTIVATEITEM = `${version}/InactivatedRailwayItem/activate`;

/** CALCULATION FORMULA */
export const RESOURCE_FORMULA = `${version}/CalculationFormula`;

/**  DOWNLOAD CENTER * */
export const TEMPLATES = `${version}/Template`;
export const TEMPLATES_UPLOAD = `${version}/Template/upload`;
export const TEMPLATES_DOWNLOAD = `${version}/Template/download`;

/**  LOCAL * */
export const LOCAL = `${version}/Local/paged`;
export const UPDATE_LOCAL = `${version}/Local`;

export const ACTIVE_CONTRACTS = `${version}/Contract/actives`;
export const NEW_ITEM = `${version}/NewContractItem`;

/** Formalization */
export const FORMALIZATION = `${version}/Formalization`;

export const FORMALIZATION_SEND = `${version}/Formalization/send-request`;

export const FORMALIZATION_RETURN_TO_PLAINTIFF = (id) =>
  `${version}/Formalization/${id}/return-to-plaintiff`;

export const FORMALIZE = `${version}/Formalization/formalize`;

export const REVIEW = `${version}/Formalization/request-review`;

export const ACCEPT_SERVICE = `${version}/Formalization/accept-service`;
export const TRANSFER_SERVICE = `${version}/Formalization/transfer-service`;

export const APPROVE_REQUEST = (id) =>
  `${version}/Formalization/pricing-approval/${id}`;
export const DISAPPROVE_REQUEST = `${version}/Formalization/pricing-disapproval`;

export const EXPECTED_DATE = (id) =>
  `${version}/Formalization/${id}/expected-date`;

export const FORMALIZATION_DRAFT_TO_REQUESTED = (id) =>
  `${version}/Formalization/${id}/request`;

export const CANCEL_FORMALIZATION = `${version}/Formalization/cancel`;

export const UPLOAD_ATTACHMENT = `${version}/Formalization/upload`;
export const DOWNLOAD_ATTACHMENT = (formalizationId) =>
  `${version}/Formalization/download/${formalizationId}`;

export const DOWNLOAD_SUMMARY = (formalizationId) =>
  `${version}/Formalization/download-summary/${formalizationId}`;

export const GET_AVAILABLE_CONTRACTS = `${version}/Formalization/available`;
export const CREATE_CONTRACT = `${version}/Formalization/create-contract`;
export const GET_AVAILABLE_CONTRACTS_DETAIL = `${version}/Formalization/contract-price-opening-detail`;

// Comments
export const COMMENT = `${version}/Formalization/observation`;

// Attendants
export const ATTENDANT = `${version}/Attendant`;

// Compensation
export const COMMITMENT = `${version}/Commitment`;

// Sales
export const GROUPED_SALES = (saleId) =>
  `${version}/Formalization/grouped-sales/${saleId}`;

// Services
export const SERVICES = (saleId) => `${version}/OpeningPriceService/${saleId}`;

// Commercial Negotiation
export const COMMERCIAL_NEGOTIATION = `${version}/ComercialNegociation`;

// Close Railway Item
export const CLOSE_RAILWAY_ITEM = `${version}/RailwayItem/close`;

// Contract Recipients By User
export const CONTRACTS_RECIPIENTS_BY_USER = (userId) =>
  `${version}/Contract/another-contracts-recipients/${userId}`;

// System Parameters
export const SYSTEM_PARAMETERS = `${version}/SystemParameter`;

// New Item
export const NEW_ITEM_ORIGIN_DESTINY = `${version}/NewContractItem/origin-destiny`;
export const NEW_ITEM_TARIFF = `${version}/NewContractItem/new-item-tariff`;
export const NEW_ITEM_GRAINS = `${version}/NewContractItem/new-item-grains`;

export const CONTRACT_ADD_SERVICES = `${version}/contract/import-monthly-contract-value-services-spreadsheet`;
