import { put, takeLatest } from 'redux-saga/effects';

import { GET } from 'constants/verbs';
import api from 'services/api';

import { ServicesActions, ServicesTypes } from './services.ducks';

export function* listServices() {
  try {
    const response = yield api({
      method: GET,
      url: '/1/FreightService',
    });

    yield put(ServicesActions.listServicesSuccess(response.data.data));
  } catch (error) {
    yield put(ServicesActions.listServicesFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(ServicesTypes.LIST_SERVICES, listServices);
}
