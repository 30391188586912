import { EmptyState, Tabs } from '@vli/locomotive-ds/dist';
import Table from 'components/core/Table';
import TableContainer from 'components/core/TableContainer';
import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';
import {
  getContractStatus,
  getCountItems,
  getDataCurved,
  getOptionsFilter,
  getRailwayItems,
} from 'lib/contexts/contracts';
import { isFinished } from 'lib/core/request';
import * as utility from 'lib/core/utility';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FilterContracts from '../../FilterContracts';
import Columns from './TableItems/Columns';

const GrainSegmentTable = () => {
  const { id } = useParams();
  const { contractDetails, contractDetailRequests } = useSelector(
    ({ contractDetail }) => contractDetail,
  );

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getOptions = getOptionsFilter(contractDetails.details);
    setOptions(getOptions);
  }, [contractDetails]);

  const list = contractDetails.details.railwayItems;

  const railwayItems = getRailwayItems(list);
  const countItems = getCountItems(list);
  const dataTable = getDataCurved(list);

  return (
    <TableContainer data-testid="contract-detail-test-grain">
      <FilterContracts
        totalTo={countItems}
        id={id}
        options={options}
        disabled={
          !utility.validateAction(
            railwayItems,
            contractDetails.details.segmentId,
            contractDetails.details,
          )
        }
        groupedItems={dataTable}
      />
      <Tabs
        position="start"
        onChange={(tabLabel) => {
          if (tabLabel && tabLabel !== contractDetails.details.code) {
            const relative = contractDetails?.details?.contractRelatives.find(
              (x) => x.contractCode === tabLabel,
            );

            const isClosed = getContractStatus({ details: relative });

            history.push(
              `${ROUTES.CONTRACT_DETAILS(relative.contractId, isClosed)}`,
            );
          }
        }}
      >
        {contractDetails?.details?.contractRelatives.map((x) => (
          <li
            key={x.contractId}
            label={x.contractCode}
            active={x.contractId === contractDetails.details.id}
          >
            {x.contractId === contractDetails.details.id &&
            dataTable.length > 0 ? (
              <Table
                width="100%"
                tableType="responsive"
                footer={false}
                columns={Columns}
                data={dataTable}
              />
            ) : (
              isFinished(contractDetailRequests.GET_CONTRACT_DETAIL) && (
                <EmptyState condensed={false} title="Nenhum item encontrado." />
              )
            )}
          </li>
        ))}
      </Tabs>
    </TableContainer>
  );
};

export default GrainSegmentTable;
