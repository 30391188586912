import CONSTANTS from './constants';

const service = 'api';

const API = `${CONSTANTS.API}/${service}/`;
const { ENDPOINTSNOTIFICATION } = CONSTANTS;

export const config = {
  API,
  ENDPOINTSNOTIFICATION,
};
