import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { read, utils } from 'xlsx';

import { Button, Input, Loading, Modal } from '@vli/locomotive-ds';
import {
  ALLOWED_FORMATS,
  cityOptions,
  clientOptions,
  destinyOptions,
  errorMessages,
  mesoRegionOptions,
  modalOptions,
  originOptions,
  plantOptions,
  productOptions,
  ROW_LIMIT,
  stateOptions,
} from 'lib/contexts/recommendedPrice';

import * as S from './ImportModalStyles';

const ImportModal = ({ isOpen, onClose, handleInsertFormFields }) => {
  const [fileInputValue, setFileInputData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileInputChange = (file) => {
    setFileInputData(file);
    setError(null);
  };

  const getSelectedOption = (field, data, options) => {
    if (data[field]) {
      const selected = options.find(
        (opt) => opt.label.toUpperCase() === data[field].toUpperCase(),
      );

      if (selected) {
        return selected.value;
      }

      return '';
    }

    setError(errorMessages.FIELD);
    return '';
  };

  const handleSpreadsheetReading = async () => {
    try {
      setLoading(true);

      if (!ALLOWED_FORMATS.includes(fileInputValue.type)) {
        setError(errorMessages.TYPE);
        setLoading(false);

        return;
      }

      const f = await fileInputValue.arrayBuffer();
      const wb = read(f);
      const ws = wb.Sheets[wb.SheetNames[0]];
      const dataJson = utils.sheet_to_json(ws);

      if (dataJson.length > ROW_LIMIT) {
        setError(errorMessages.LIMIT);
        setLoading(false);

        return;
      }

      const recommendedPrices = dataJson.map((data) => {
        const endDate = data?.['Data Vig. Final']
          ? new Date(data['Data Vig. Final'])
          : null;
        const movementDate = new Date(data.Ano, data['Mês'], 1);

        return {
          endDate,
          movementDate,
          product: getSelectedOption('Produto', data, productOptions),
          modal: getSelectedOption('Modal', data, modalOptions),
          client: getSelectedOption('Cliente', data, clientOptions),
          origin: getSelectedOption('Origem', data, originOptions),
          destiny: getSelectedOption('Destino', data, destinyOptions),
          state: getSelectedOption('Estado', data, stateOptions),
          city: getSelectedOption('Cidade', data, cityOptions),
          plant: getSelectedOption('Usina', data, plantOptions),
          mesoRegion: getSelectedOption('Mesorregião', data, mesoRegionOptions),
          tariff: data['Tarifa Cliente'],
        };
      });

      handleInsertFormFields(recommendedPrices);

      setLoading(false);
      setFileInputData(null);
      onClose();
    } catch (err) {
      setLoading(false);
      setError(errorMessages.PROCESS);
    }
  };

  const handleCloseModal = () => {
    onClose();
    setLoading(false);
    setFileInputData(null);
    setError(null);
  };

  return (
    <Modal
      title="Importar novo arquivo"
      isOpen={isOpen}
      onClose={handleCloseModal}
    >
      <S.FieldContainer>
        <Input
          type="file"
          placeholder="Escolha o arquivo excel para importar"
          disabled={false}
          required={false}
          accept=".xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          value={fileInputValue}
          onChange={handleFileInputChange}
        />

        <Button
          name="Enviar"
          type="button"
          action={handleSpreadsheetReading}
          disabled={!fileInputValue}
        />
      </S.FieldContainer>

      <S.EventsContainer>
        {error && !loading && <S.ErrorText>{error}</S.ErrorText>}

        {loading && (
          <S.LoadingContainer>
            <S.LoadingWrapper>
              <Loading fitParent type="secondary" size="16px" />
            </S.LoadingWrapper>

            <S.LoadingText>
              Realizando leitura do arquivo, aguarde por favor!
            </S.LoadingText>
          </S.LoadingContainer>
        )}
      </S.EventsContainer>
    </Modal>
  );
};

ImportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImportModal;
