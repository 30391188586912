import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';
import { createActions, createReducer } from 'reduxsauce';

export const {
  Types: ServicesTypes,
  Creators: ServicesActions,
} = createActions({
  listServices: [],
  listServicesSuccess: ['payload'],
  listServicesFailure: ['error'],
});

const INITIAL_STATE = {
  services: [],
  error: null,
  servicesRequests: {
    [ServicesTypes.LIST_SERVICES]: REQUEST_NOT_STARTED,
  },
};

const listServices = () => ({
  ...INITIAL_STATE,
  servicesRequests: {
    ...INITIAL_STATE.servicesRequests,
    [ServicesTypes.LIST_SERVICES]: REQUEST_PENDING,
  },
});

const listServicesSuccess = (state, { payload }) => ({
  ...state,
  services: payload,
  servicesRequests: {
    ...state.servicesRequests,
    [ServicesTypes.LIST_SERVICES]: REQUEST_RESOLVED,
  },
});

const listServicesFailure = (state, { error: { response } }) => ({
  ...state,
  error: response,
  servicesRequests: {
    ...state.servicesRequests,
    [ServicesTypes.LIST_SERVICES]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [ServicesTypes.LIST_SERVICES]: listServices,
  [ServicesTypes.LIST_SERVICES_SUCCESS]: listServicesSuccess,
  [ServicesTypes.LIST_SERVICES_FAILURE]: listServicesFailure,
});
