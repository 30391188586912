const ACCESS_TOKEN = 'access-token';
const ID = 'id';
const REFRESH_TOKEN = 'refresh-token';
const VALID = 'valid';
const USER_NAME = 'firstName';
const USER_LAST_NAME = 'lastName';
const USER_ROLE = 'role';
const USER_AVATAR = 'avatar';
const USER_ID = 'id';
const USER_LANGUAGE = 'language';
const USER_EMAIL = 'email';
const USER_COUNTRY = 'country';
const USER_PROFILE = 'profile';

export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN);

export const setAccessToken = ({ accessToken, id, refreshToken, valid }) => {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
  localStorage.setItem(ID, id);
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
  localStorage.setItem(VALID, valid);
};

export const createUserSession = ({
  avatar,
  firstName,
  lastName,
  role,
  email,
  language,
  country,
  profile,
}) => {
  localStorage.setItem(USER_NAME, firstName);
  localStorage.setItem(USER_LAST_NAME, lastName);
  localStorage.setItem(USER_ROLE, role);
  localStorage.setItem(USER_AVATAR, avatar);
  localStorage.setItem(USER_LANGUAGE, language);
  localStorage.setItem(USER_EMAIL, email);
  localStorage.setItem(USER_COUNTRY, country);
  localStorage.setItem(USER_PROFILE, profile);
};

export const retrieveUserSession = () => ({
  firstName: localStorage.getItem(USER_NAME),
  lastName: localStorage.getItem(USER_LAST_NAME),
  role: localStorage.getItem(USER_ROLE),
  avatar: localStorage.getItem(USER_AVATAR),
  id: localStorage.getItem(USER_ID),
  email: localStorage.getItem(USER_EMAIL),
  country: localStorage.getItem(USER_COUNTRY),
  profile: localStorage.getItem(USER_PROFILE),
});

export const removeClaims = () => {
  const language = localStorage.getItem(USER_LANGUAGE);

  localStorage.clear();
  localStorage.setItem(USER_LANGUAGE, language);
};

export const isAuthenticated = () => getAccessToken() !== null;
