import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import accountManager from './accountManager/accountManager.ducks';
import activeContracts from './activeContracts/activeContracts.ducks';
import attendant from './attendant/attendant.ducks';
import auth from './auth/auth.ducks';
import comments from './comments/comments.ducks';
import commercialNegotiations from './commercialNegotiations/commercialNegotiations.ducks';
import compensation from './compensation/compensation.ducks';
import contract from './contract/contract.ducks';
import contractDetail from './contractDetail/contractDetail.ducks';
import contractReport from './contractReport/contractReport.ducks';
import contractUpload from './contractUpload/contractUpload.ducks';
import contractsControl from './contractsControl/contractsControl.ducks';
import customer from './customer/customer.ducks';
import diesel from './diesel/diesel.ducks';
import emailNotificationClient from './emailNotificationsClient/emailNotificationClient.ducks';
import emailNotificationManager from './emailNotificationsManager/emailNotificationManager.ducks';
import formalizationRequest from './formalizationRequest/formalizationRequest.ducks';
import formulas from './formulas/formulas.ducks';
import indices from './indices/indices.ducks';
import indicesHistory from './indicesHistory/indicesHistory.ducks';
import local from './local/local.ducks';
import messages from './messages/messages.ducks';
import model from './model/model.ducks';
import newItems from './newItems/newItems.ducks';
import notification from './notification/notification.ducks';
import profile from './profile/profile.ducks';
import railwayItem from './railwayItem/railwayItem.ducks';
import railwayItemsHistory from './railwayItemsHistory/railwayItemsHistory.ducks';
import reactivateItems from './reactivateItems/reactivateItems.ducks';
import reportContractItems from './reportContractItems/reportContractItems.ducks';
import requestsList from './requestsList/requestsList.ducks';
import resendItems from './resendItems/resendItems.ducks';
import segment from './segment/segment.ducks';
import services from './services/services.ducks';
import status from './status/status.ducks';
import supplier from './supplier/supplier.ducks';
import systemParameters from './systemParameters/systemParameters.ducks';
import user from './user/user.ducks';

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    contract,
    contractDetail,
    accountManager,
    customer,
    railwayItemsHistory,
    indicesHistory,
    indices,
    messages,
    diesel,
    status,
    profile,
    segment,
    user,
    local,
    activeContracts,
    newItems,
    resendItems,
    reactivateItems,
    emailNotificationClient,
    emailNotificationManager,
    reportContractItems,
    contractReport,
    supplier,
    railwayItem,
    model,
    formulas,
    formalizationRequest,
    requestsList,
    notification,
    comments,
    attendant,
    compensation,
    commercialNegotiations,
    systemParameters,
    contractUpload,
    contractsControl,
    services,
  });

const Reducers = (history) => appReducer(history);

export default Reducers;
