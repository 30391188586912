import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '@vli/locomotive-ds/dist';

import * as S from './ResendModalStyles';

const ResendModal = ({ isOpen, onClose, onConfirm }) => {
  return (
    <S.ModalContainer
      title="Reenvio de Email"
      isOpen={isOpen}
      onClose={onClose}
      width="650px"
    >
      <S.Wrapper>
        <p>
          Ao reenviar email, você está ciente de que será enviado para lista de
          destinatário as informações da última aprovação de preço.
        </p>

        <S.Actions>
          <Button
            variant="secondary"
            name="cancelar"
            type="button"
            onClick={onClose}
          />

          <Button
            variant="primary"
            name="confirmar"
            type="button"
            onClick={onConfirm}
          />
        </S.Actions>
      </S.Wrapper>
    </S.ModalContainer>
  );
};

ResendModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ResendModal;
