import axios from 'axios';
import { history } from 'constants/routes';
import { config } from 'constants/urlApis';
import * as ROUTES from 'constants/urls';
import jwt from 'jsonwebtoken';
import { GET } from 'lib/core/localStorage';
import { store } from 'store';
import { AuthActions } from 'store/auth/auth.ducks';

export const notificationApi = axios.create({
  baseURL: config.ENDPOINTSNOTIFICATION,
});

const api = axios.create({
  baseURL: config.API,
  headers: {
    'Content-Type': 'application/json',
  },
});

const verifyToken = (token) => {
  const { exp } = jwt.decode(token);
  const dateNow = new Date().getTime() / 1000;

  if (exp && exp < dateNow) {
    store.dispatch(AuthActions.openAuthModal());

    throw new Error('Sessão expirada');
  }
};

api.interceptors.request.use(
  async (request) => {
    request.headers.common['Content-Type'] = 'application/json';

    const authToken = GET('access-token');

    if (authToken.length) {
      verifyToken(authToken);

      const accessToken = `Bearer ${authToken}`;
      request.headers.common.Authorization = accessToken;
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const {
      response: { status },
    } = error;

    if (status && status === 401) {
      history.push(ROUTES.LOGIN);

      throw error;
    } else if (status === 403) {
      history.replace(ROUTES.FORBIDDEN);
      throw error;
    }
    return Promise.reject(error);
  },
);

export default api;
