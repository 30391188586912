import * as Yup from 'yup';

import { ACTIONS, canExecuteAction, ROLES } from 'lib/contexts/permissions';
import { GET } from 'lib/core/localStorage';
import * as utility from 'lib/core/utility';

export const setMonthsDataFromStringToNumber = (data) => {
  const dataFormatted = data.map((month) => {
    const formatted =
      month.value !== null
        ? { ...month, value: parseFloat(month.value.replace(',', '.')) }
        : month;
    return formatted;
  });
  return dataFormatted;
};

export const monthsData = [
  {
    month: 1,
    value: null,
  },
  {
    month: 2,
    value: null,
  },
  {
    month: 3,
    value: null,
  },
  {
    month: 4,
    value: null,
  },
  {
    month: 5,
    value: null,
  },
  {
    month: 6,
    value: null,
  },
  {
    month: 7,
    value: null,
  },
  {
    month: 8,
    value: null,
  },
  {
    month: 9,
    value: null,
  },
  {
    month: 10,
    value: null,
  },
  {
    month: 11,
    value: null,
  },
  {
    month: 12,
    value: null,
  },
];

const mapFareValues = (fareType, fareValues, inCurveData) => {
  if (fareType === 1) {
    return setMonthsDataFromStringToNumber(inCurveData);
  }

  if (fareValues) {
    return [
      {
        month: null,
        value: parseFloat(fareValues.replace(',', '.')),
      },
    ];
  }

  return null;
};

const mapServices = (services) => {
  if (!services) {
    return [];
  }

  const mappedServices = services.map((service) => {
    let months = [];

    if (service.months.length === 1) {
      months.push({
        month: null,
        value: parseFloat(service.months[0]?.value.replace(',', '.')),
      });
    }

    if (service.months.length > 1) {
      months = service.months.map((month, index) => {
        return {
          month: String(index + 1),
          value: parseFloat(month.value.replace(',', '.')),
        };
      });
    }

    return {
      freightServiceId: service.serviceId,
      readjustableDiesel: service.dieselReadjustment,
      readjustableInflation: service.inflationReadjustment,
      months,
    };
  });

  return mappedServices;
};

// Status: 0 - RASCUNHO e 1 - VINCULADO
// originType: 0 - MANUAL e 1 - INTEGRACAO
// fareType: 0 - FIXA e 1 - CURVA
export const getNewItemDataSend = (data, segmentId, status, inCurveData) => {
  return {
    codeItem: data.railwayId,
    codeContract: data.contract,
    localId: data?.diesel,
    status,
    originType: 0,
    origin: data?.cargoOrigin,
    originCode: data?.originCode,
    destination: data?.destination,
    destinationCode: data?.destinationCode,
    railwayInvolved: data?.railwayInvolved,
    segmentId,
    productUnicomMerchandiseTacito: data?.product,
    placeOriginCargo: data?.placeOriginCargo,
    hasTrigger: data?.trigger !== 0,
    triggerPercentage: data?.triggerPercentage
      ? Number(data.triggerPercentage)
      : null,
    dataBase: data?.dataBase,
    correctionStartDate: data?.correctionStartDate,
    correctionEndDate: data?.correctionEndDate,
    fareType: data?.fareType,
    fareValues: mapFareValues(data?.fareType, data?.fareValues, inCurveData),
    monthlyContractValueServices: mapServices(data?.services),
  };
};

export const DataFilter = (contracts, segments, items, baseDiesel) => {
  const contractData = contracts.map((contract) => ({
    label: contract.code,
    value: {
      id: contract.code,
      others: '',
    },
  }));
  const segmentData = segments.map((segment) => ({
    label: segment.name,
    value: {
      id: segment.id,
      others: '',
    },
  }));
  const itemData = items.map((item) => ({
    label: item.name,
    value: {
      id: item.id,
      others: '',
    },
  }));
  const baseDieselData = baseDiesel.map((diesel) => ({
    label: diesel.name,
    value: {
      id: diesel.id,
      others: '',
    },
  }));

  const dataFilter = [
    {
      data: contractData,
      label: 'Contrato',
      name: 'contract',
      type: 'multiselect',
    },
    {
      data: segmentData,
      label: 'Segmento Comercial',
      name: 'segment',
      type: 'multiselect',
    },
    {
      data: itemData,
      label: 'Item ferroviário',
      type: 'multiselect',
      name: 'item',
    },
    {
      data: baseDieselData,
      label: 'Base Diesel',
      type: 'multiselect',
      name: 'diesel',
    },
  ];
  return dataFilter;
};

export const getFilterParams = (data) => {
  const contractIds = data.contract
    ? data.contract.map((contract) => contract.value.id)
    : null;
  const baseDieselIds = data.diesel
    ? data.diesel.map((diesel) => diesel.value.id)
    : null;
  const itemIds = data.item ? data.item.map((item) => item.value.id) : null;
  const segmentIds = data.segment
    ? data.segment.map((segment) => segment.value.id)
    : null;
  const filterFields = {
    filterFields: {
      CodeContract: contractIds,
      LocalId: baseDieselIds,
      CodeItem: itemIds,
      SegmentId: segmentIds,
      PageIndex: data.pageIndex,
      PageSize: data.pageSize,
    },
  };
  return `?${utility.autoListingParamsGet(filterFields)}`;
};

export const setBaseDieselLocalTypeNameOptions = (data) => {
  if (data) {
    // get objects with names
    const duplicateNames = data
      .map((e) => e.name)
      .map((e, i, final) => final.indexOf(e) !== i && i)
      .filter((obj) => data[obj])
      .map((e) => data[e].name);

    // get only names
    const getDuplicates = data.filter((obj) =>
      duplicateNames.includes(obj.name),
    );

    // get only ids of objects with same names
    const duplicateObjectsId = getDuplicates.map((obj) => obj.id);
    // going to get the objects with same name and change it, to make different names
    const changeName = getDuplicates.map((obj) => {
      return { ...obj, name: `${obj.name} - ${obj.typeLocal}` };
    });

    // get array without objects with same names
    const getArrayWithoutDuplicates = data.filter(
      (obj) => !duplicateObjectsId.includes(obj.id),
    );

    return getArrayWithoutDuplicates
      .concat(changeName)
      .sort((a, b) => a.name.localeCompare(b.name));
  }
  return data;
};

export const canEditNewItem = () => {
  const { roles } = GET('roles');

  return canExecuteAction(ROLES.NEW_ITEMS, ACTIONS.UPDATE, roles);
};

export const modeEditRow = (newItems, row, editMode) => {
  return newItems?.map((newItem) => {
    if (row.id === newItem.id) {
      return {
        ...newItem,
        editMode: true,
      };
    }

    return {
      ...newItem,
      editMode: editMode ? false : null,
    };
  });
};

export const resetEditRow = (newItems) => {
  return newItems?.map((newItem) => {
    return {
      ...newItem,
      editMode: false,
    };
  });
};

export const newItemsFormSchema = Yup.object().shape({
  contract: Yup.string().required(),
  railwayId: Yup.string().required(),
  cargoOrigin: Yup.string().nullable(),
  originCode: Yup.string().nullable(),
  destination: Yup.string().nullable(),
  destinationCode: Yup.string().nullable(),
  segment: Yup.string().nullable(),
  railwayInvolved: Yup.string().nullable(),
  placeOriginCargo: Yup.string().nullable(),
  trigger: Yup.number().nullable(),
  triggerPercentage: Yup.string().nullable(),
  product: Yup.string().nullable(),
  dataBase: Yup.date().nullable(),
  diesel: Yup.string().uuid().nullable(),
  correctionStartDate: Yup.number().nullable(),
  correctionEndDate: Yup.number().nullable(),
  fareType: Yup.number(),
  fareValues: Yup.string().nullable(),
  services: Yup.array().of(
    Yup.object().shape({
      serviceId: Yup.string().required(),
      dieselReadjustment: Yup.boolean(),
      inflationReadjustment: Yup.boolean(),
      months: Yup.array().of(
        Yup.object().shape({
          value: Yup.string().required(),
        }),
      ),
    }),
  ),
});

export const formDefaultValues = {
  contract: '',
  railwayId: null,
  cargoOrigin: null,
  originCode: null,
  destination: null,
  destinationCode: null,
  segment: null,
  railwayInvolved: null,
  placeOriginCargo: null,
  trigger: 0,
  triggerPercentage: null,
  product: null,
  dataBase: null,
  correctionStartDate: 0,
  correctionEndDate: 0,
  fareType: 0,
  fareValues: null,
  services: [
    {
      serviceId: '',
      dieselReadjustment: false,
      inflationReadjustment: false,
      months: [
        {
          value: '',
        },
      ],
    },
  ],
};
