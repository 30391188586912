import { Modal, Tokens } from '@vli/locomotive-ds';
import styled from 'styled-components';

export const CustomModal = styled(Modal)`
  #divider {
    display: none;
  }

  form {
    width: 430px;
    margin-top: ${Tokens.spacing.md};

    display: flex;
    flex-direction: column;
    gap: ${Tokens.spacing.sm};

    label {
      font-weight: 400;
      color: ${Tokens.colors.gray400};
    }

    textarea {
      font: ${Tokens.fonts.textRegularMedium};
      font-weight: 400;

      &::placeholder {
        color: ${Tokens.colors.gray300};
      }
    }

    button span {
      color: ${Tokens.colors.gray500};
    }
  }
`;

export const InputContainer = styled.div`
  & > span {
    font: ${Tokens.fonts.textRegularSmall};
    display: block;
    text-align: right;
    margin-top: 5px;
    z-index: 1;
    color: #a32626;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${Tokens.spacing.xs};
`;
