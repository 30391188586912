import { Tokens } from '@vli/locomotive-ds/dist';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle` 
  * {    
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  body {
    margin:0;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
   font: ${Tokens.fonts.textRegularMedium};
   main {
    background: #f6f7fa !important;
   }

   // show text only for screen readers
   .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
   }
  }
`;
export default GlobalStyle;
