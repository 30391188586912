import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Col, Input, Row } from '@vli/locomotive-ds/dist';

import InputSelect from 'components/form/InputSelect';

import handleInputMask from 'lib/core/handleInputMask';
import * as utility from 'lib/core/utility';

import TableRate from '../../contractedRate/TableRate/TableRate';

import * as S from '../Form.styles';

const ContractedTariffSection = ({ setInCurveDataFromTable, isCurvedFare }) => {
  const { control, errors } = useFormContext();

  const getTableData = (tableData) => {
    return setInCurveDataFromTable(tableData);
  };

  return (
    <S.ExpandedAccordion title="Tarifa contratada" open>
      <Row>
        <Col desktop={2}>
          <Controller
            control={control}
            name="fareType"
            render={({ onChange, value }) => (
              <InputSelect
                value={value}
                options={utility.optionsContractedFare}
                onChange={onChange}
                placeholder="Selecione"
                label=""
                type="text"
                maxLength="50"
                disabled
                styles={{ marginRight: '10px' }}
                status={errors.fareType && 'error'}
                statusMessage={errors.fareType && 'Campo requerido'}
              />
            )}
          />
        </Col>
        <Col desktop={1}>
          <Controller
            control={control}
            name="fareValues"
            render={({ onChange, value }) => (
              <Input
                value={value}
                onChange={onChange}
                placeholder={isCurvedFare ? 'meses' : 'R$'}
                label=""
                type="input"
                maxLength="8"
                disabled={isCurvedFare}
                status={errors.fareValues && 'error'}
                statusMessage={errors.fareValues && 'Campo requerido'}
                onInput={(event) => handleInputMask(event, 'currency')}
              />
            )}
          />
        </Col>
      </Row>
      {isCurvedFare && (
        <Row style={{ marginTop: '10px' }}>
          <TableRate sendData={getTableData} />
        </Row>
      )}
    </S.ExpandedAccordion>
  );
};

export default ContractedTariffSection;
