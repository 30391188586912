import { Flex } from '@vli/locomotive-ds/dist';
import FilterDS from 'components/core/FilterDs';
import { DataFilter, getFilterParams } from 'lib/contexts/resendItems';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActiveContractActions } from 'store/activeContracts/activeContracts.ducks';
import { ResendItemsActions } from 'store/resendItems/resendItems.ducks';
import * as S from './FilterResendItems.styles';

const FilterResendItems = () => {
  const dispatch = useDispatch();
  const [dataFilter, setDataFilter] = useState({});

  const { activeContractList } = useSelector(
    ({ activeContracts }) => activeContracts,
  );

  useEffect(() => {
    dispatch(ActiveContractActions.activeContract());
  }, [dispatch]);

  const filterAction = (filterData) => {
    const data = getFilterParams({ ...filterData });
    dispatch(ResendItemsActions.resendItemsListRequest(data ?? '', filterData));
  };

  const onConfirm = (data) => {
    filterAction(data);
    setDataFilter(data);
  };

  const onClean = () => {
    setDataFilter({});
    filterAction({});
  };

  return (
    <Flex justifyContent="space-between">
      <S.WrapperFilter data-testid="dts-filter">
        <FilterDS
          defaultSelectedItems={dataFilter}
          listItems={DataFilter(activeContractList)}
          onConfirm={onConfirm}
          onClean={onClean}
          btnMright="8px"
          withText={false}
        />
      </S.WrapperFilter>
    </Flex>
  );
};

export default FilterResendItems;
