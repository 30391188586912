import React from 'react';

import * as utility from 'lib/core/utility';

import { history } from 'constants/routes';
import * as ROUTES from 'constants/urls';

import CellGroupItems from 'components/contexts/contract/ContractDetail/Tables/CellGroupItems';
import CellStatusTable from 'components/contexts/contract/ContractDetail/Tables/CellStatusTable';
import CellWithActions from 'components/contexts/contract/ContractDetail/Tables/CellWithActions';
import CellTable from 'components/core/Table/CellTable';

const onHandle = (row) => {
  history.push(
    ROUTES.RAILWAY_ITEMS_HISTORY_PARAMS(
      row.railwayItemCodeGrouping[0],
      row.status?.toLowerCase(),
      row.railwayItemCodeGrouping,
    ),
  );
};

const Columns = [
  {
    Header: 'Origem/Destino',
    accessor: 'originDestination',
    align: 'start',
    width: 100,
    Cell: (props) => <CellTable {...props} onClick={onHandle} />,
  },
  {
    Header: 'Item',
    accessor: 'railwayItemCodeGrouping',
    align: 'start',
    width: 200,
    Cell: (props) => (
      <CellGroupItems
        {...props}
        transformedData={utility.joinComma}
        onClick={onHandle}
      />
    ),
  },
  {
    Header: 'Produto',
    accessor: 'productGrouping',
    align: 'start',
    width: 200,
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={utility.joinComma}
        onClick={onHandle}
      />
    ),
  },
  {
    Header: 'Microrregião',
    accessor: 'grupingMicroregionName',
    align: 'start',
    width: 200,
    Cell: (props) => <CellTable {...props} onClick={onHandle} />,
  },
  {
    Header: 'Contratado',
    accessor: 'valueContracted',
    align: 'start',
    minWidth: 40,
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={utility.formatMoney}
        onClick={onHandle}
      />
    ),
  },
  {
    Header: 'Calculado',
    accessor: 'calculatedValue.value',
    align: 'start',
    minWidth: 40,
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={utility.formatMoney}
        onClick={onHandle}
      />
    ),
  },
  {
    Header: 'Status Preço',
    accessor: 'statusHistory',
    align: 'start',
    Cell: (props) => <CellStatusTable {...props} onClick={onHandle} />,
    width: 200,
  },
  {
    Header: 'Ações',
    accessor: 'action',
    align: 'end',
    width: 50,
    Cell: (props) => <CellWithActions {...props} isGrouped />,
  },
];

export default Columns;
