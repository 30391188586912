import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Button, Input, InputDate } from '@vli/locomotive-ds/dist';

import { ContractActions } from 'store/contract/contract.ducks';

import * as S from './ServiceStyles';

const schema = Yup.object().shape({
  servicesStartDate: Yup.string().required('Campo obrigatório'),
  servicesFile: Yup.mixed().required('Campo obrigatório'),
  evidenceFile: Yup.mixed().required('Campo obrigatório'),
});

const Service = ({ isOpen, onClose }) => {
  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      servicesStartDate: '',
    },
    resolver: yupResolver(schema),
  });

  const { id: contractId } = useParams();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    const formData = new FormData();
    const enPeriodToISO = new Date(data.servicesStartDate).toISOString();

    formData.append('ContractId', contractId);
    formData.append('ServicesStartDate', enPeriodToISO);
    formData.append('MonthlyContractValueServicesFile', data.servicesFile);
    formData.append('EvidenceFile', data.evidenceFile);

    dispatch(ContractActions.addServices(formData));
  };

  return (
    <S.CustomModal
      title="Abertura por serviços"
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="servicesFile"
          control={control}
          render={({ ...field }) => (
            <Input
              data-testid="input-select-service-file"
              type="file"
              label="Anexar arquivo"
              placeholder="Selecione o arquivo"
              accept=".xlsx"
              required
              {...field}
              status={errors?.servicesFile && 'error'}
              statusMessage={errors?.servicesFile?.message}
            />
          )}
        />

        <Controller
          name="servicesStartDate"
          control={control}
          rules={{ required: true }}
          render={({ ...field }) => (
            <S.InputContainer>
              <InputDate
                {...field}
                label="Data Inicio Serviços"
                format="dd/MM/yyyy"
                locale="pt-BR"
              />
              {errors?.servicesStartDate && (
                <span>{errors?.servicesStartDate?.message}</span>
              )}
            </S.InputContainer>
          )}
        />

        <Controller
          name="evidenceFile"
          control={control}
          render={({ ...field }) => (
            <Input
              data-testid="input-select-evidence-file"
              type="file"
              label="Anexar evidência"
              placeholder="Selecione o arquivo"
              accept="image/jpg, image/jpeg, image/png, application/pdf"
              required
              {...field}
              status={errors?.evidenceFile && 'error'}
              statusMessage={errors?.evidenceFile?.message}
            />
          )}
        />

        <S.Actions>
          <Button variant="secondary" name="cancelar" onClick={onClose} />

          <Button variant="primary" name="salvar" type="submit" />
        </S.Actions>
      </form>
    </S.CustomModal>
  );
};

Service.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Service;
