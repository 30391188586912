import React from 'react';

import * as utility from 'lib/core/utility';

import CellTable from 'components/core/Table/CellTable';

export const Columns = [
  {
    Header: 'Clientes',
    accessor: 'name',
    align: 'start',
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={utility.capitalizeFirstString}
        align="start"
      />
    ),
  },
  {
    Header: 'CNPJ',
    accessor: 'cnpj',
    align: 'start',
    Cell: (props) => (
      <CellTable
        {...props}
        transformedData={utility.formatStringCNPJ}
        align="start"
      />
    ),
  },
];
