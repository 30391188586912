import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from 'constants/request';
import { createActions, createReducer } from 'reduxsauce';

export const {
  Types: EmailNotificationClientTypes,
  Creators: EmailNotificationClientActions,
} = createActions({
  getEmailNotificationClient: ['payload'],
  getEmailNotificationClientSuccess: ['payload'],
  createEmailNotificationClient: ['payload', 'callback'],
  createEmailNotificationClientSuccess: ['payload'],
  listEmailNotificationClient: ['params'],
  listEmailNotificationClientFilter: ['params', 'rawFilter'],
  listEmailNotificationClientSuccess: ['payload'],
  resendEmailNotificationClient: ['payload', 'callback'],
  resendEmailNotificationClientSuccess: [],
  emailNotificationClientRequestFailure: ['error', 'originalType'],
});

const INITIAL_STATE = {
  emailNotificationClientList: [],
  params: null,
  filter: null,
  details: null,
  error: null,
  emailNotificationClientRequests: {
    [EmailNotificationClientTypes.LIST_EMAIL_NOTIFICATION_CLIENT]: REQUEST_NOT_STARTED,
  },
};

const listEmailNotificationClient = () => ({
  ...INITIAL_STATE,
  emailNotificationClientRequests: {
    ...INITIAL_STATE.emailNotificationClientRequests,
    [EmailNotificationClientTypes.LIST_EMAIL_NOTIFICATION_CLIENT]: REQUEST_PENDING,
  },
});

const listEmailNotificationClientFilter = (state, { params, rawFilter }) => ({
  ...state,
  ...INITIAL_STATE,
  params,
  filter: rawFilter,
  emailNotificationClientRequests: {
    ...INITIAL_STATE.emailNotificationClientRequests,
    [EmailNotificationClientTypes.LIST_EMAIL_NOTIFICATION_CLIENT]: REQUEST_RESOLVED,
  },
});

const listEmailNotificationClientSuccess = (state, { payload }) => ({
  ...state,
  emailNotificationClientList: payload,
  emailNotificationClientRequests: {
    ...state.emailNotificationClientRequests,
    [EmailNotificationClientTypes.LIST_EMAIL_NOTIFICATION_CLIENT]: REQUEST_RESOLVED,
  },
});

const getEmailNotificationClient = () => ({
  ...INITIAL_STATE,
  emailNotificationClientRequests: {
    ...INITIAL_STATE.emailNotificationClientRequests,
    [EmailNotificationClientTypes.GET_EMAIL_NOTIFICATION_CLIENT]: REQUEST_PENDING,
  },
});

const getEmailNotificationClientSuccess = (state, { payload }) => ({
  ...state,
  details: payload,
  emailNotificationClientRequests: {
    ...state.emailNotificationClientRequests,
    [EmailNotificationClientTypes.GET_EMAIL_NOTIFICATION_CLIENT]: REQUEST_RESOLVED,
  },
});

const createEmailNotificationClient = (state) => ({
  ...state,
  emailNotificationClientRequests: {
    ...INITIAL_STATE.emailNotificationClientRequests,
    [EmailNotificationClientTypes.CREATE_EMAIL_NOTIFICATION_CLIENT]: REQUEST_PENDING,
  },
});

const createEmailNotificationClientSuccess = (state, { payload }) => ({
  ...state,
  details: payload,
  emailNotificationClientRequests: {
    ...state.emailNotificationClientRequests,
    [EmailNotificationClientTypes.CREATE_EMAIL_NOTIFICATION_CLIENT]: REQUEST_RESOLVED,
  },
});

const resendEmailNotificationClient = () => ({
  ...INITIAL_STATE,
  emailNotificationClientRequests: {
    ...INITIAL_STATE.emailNotificationClientRequests,
    [EmailNotificationClientTypes.RESEND_EMAIL_NOTIFICATION_CLIENT]: REQUEST_PENDING,
  },
});

const resendEmailNotificationClientSuccess = (state) => ({
  ...state,
  emailNotificationClientRequests: {
    ...state.emailNotificationClientRequests,
    [EmailNotificationClientTypes.RESEND_EMAIL_NOTIFICATION_CLIENT]: REQUEST_RESOLVED,
  },
});

const emailNotificationClientRequestFailure = (
  state,
  { error: { response }, originalType },
) => ({
  ...state,
  error: response,
  emailNotificationClientRequests: {
    ...state.emailNotificationClientRequests,
    [originalType]: REQUEST_REJECTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [EmailNotificationClientTypes.LIST_EMAIL_NOTIFICATION_CLIENT]: listEmailNotificationClient,
  [EmailNotificationClientTypes.LIST_EMAIL_NOTIFICATION_CLIENT_FILTER]: listEmailNotificationClientFilter,
  [EmailNotificationClientTypes.LIST_EMAIL_NOTIFICATION_CLIENT_SUCCESS]: listEmailNotificationClientSuccess,
  [EmailNotificationClientTypes.GET_EMAIL_NOTIFICATION_CLIENT]: getEmailNotificationClient,
  [EmailNotificationClientTypes.GET_EMAIL_NOTIFICATION_CLIENT_SUCCESS]: getEmailNotificationClientSuccess,
  [EmailNotificationClientTypes.CREATE_EMAIL_NOTIFICATION_CLIENT]: createEmailNotificationClient,
  [EmailNotificationClientTypes.CREATE_EMAIL_NOTIFICATION_CLIENT_SUCCESS]: createEmailNotificationClientSuccess,
  [EmailNotificationClientTypes.RESEND_EMAIL_NOTIFICATION_CLIENT]: resendEmailNotificationClient,
  [EmailNotificationClientTypes.RESEND_EMAIL_NOTIFICATION_CLIENT_SUCCESS]: resendEmailNotificationClientSuccess,
  [EmailNotificationClientTypes.EMAIL_NOTIFICATION_CLIENT_REQUEST_FAILURE]: emailNotificationClientRequestFailure,
});
