import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { Button, Container, Flex, Header } from '@vli/locomotive-ds';

import useDimensions from 'hooks/useDimensions';
import { formDefaultValues } from 'lib/contexts/recommendedPrice';

import * as ROUTES from 'constants/urls';

import PriceForm from 'components/contexts/recommendedPrice/PriceForm';
import Content from 'components/core/content';

const formSchema = Yup.object().shape({
  recommendedPrice: Yup.array().of(
    Yup.object().shape({
      endDate: Yup.date().notRequired(),
      movementDate: Yup.date().required('Campo requerido'),
      product: Yup.string().required('Campo requerido'),
      modal: Yup.string().required('Campo requerido'),
      client: Yup.string().notRequired(),
      origin: Yup.string().required('Campo requerido'),
      destiny: Yup.string().notRequired(),
      state: Yup.string().notRequired(),
      city: Yup.string().notRequired(),
      plant: Yup.string().notRequired(),
      mesoRegion: Yup.string().notRequired(),
      tariff: Yup.number().required('Campo requerido'),
    }),
  ),
});

const RecommendedPrice = () => {
  const dimensions = useDimensions();
  const methods = useForm({
    defaultValues: formDefaultValues,
    resolver: yupResolver(formSchema),
  });

  const [isOpen, setIsOpen] = useState(false);

  const breadcrumb = [
    {
      text: 'Preço Recomendado',
      href: `#${ROUTES.PRICE_RECOMMENDATION}`,
    },
  ];

  const sendRecommendedPrice = (data) => {
    console.log(data);
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <FormProvider {...methods}>
      <Content
        height={dimensions.height}
        data-testid="recommended-price-content"
      >
        <Container>
          <form onSubmit={methods.handleSubmit(sendRecommendedPrice)}>
            <Flex justifyContent="space-between" alignItems="center">
              <Header
                bread={breadcrumb}
                tag="header"
                title="Preço Recomendado"
              />

              <Flex spaceChildren="16px">
                <Button
                  name="Importar arquivo"
                  type="button"
                  variant="secondary"
                  action={handleOpenModal}
                />
                <Button name="Salvar" type="submit" />
              </Flex>
            </Flex>

            <PriceForm isOpen={isOpen} handleCloseModal={handleCloseModal} />
          </form>
        </Container>
      </Content>
    </FormProvider>
  );
};

export default RecommendedPrice;
