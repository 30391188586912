import React, { useEffect, useMemo } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import {
  AddIcon,
  Button,
  Checkbox,
  Flex,
  Input,
  TrashIcon,
} from '@vli/locomotive-ds';

import InputSelect from 'components/form/InputSelect';

import handleInputMask from 'lib/core/handleInputMask';
import * as utility from 'lib/core/utility';

import * as S from '../Form.styles';

const ServicesSection = ({ isCurvedFare }) => {
  const { control, errors, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'services',
  });

  const { services } = useSelector((state) => state.services);
  const { draftNewItem } = useSelector((state) => state.newItems);

  useEffect(() => {
    if (draftNewItem?.monthlyContractValueServices) {
      draftNewItem.monthlyContractValueServices.forEach(
        (serviceItem, serviceIndex) => {
          setValue(
            `services.${serviceIndex}.serviceId`,
            serviceItem.freightServiceId,
          );
          setValue(
            `services.${serviceIndex}.dieselReadjustment`,
            serviceItem.readjustableDiesel,
          );
          setValue(
            `services.${serviceIndex}.inflationReadjustment`,
            serviceItem.readjustableInflation,
          );

          serviceItem.months.forEach((monthItem, monthIndex) => {
            if (!monthItem.month) {
              setValue(
                `services.${serviceIndex}.months.${0}.value`,
                String(monthItem.value).replace('.', ','),
              );
            } else {
              setValue(
                `services.${serviceIndex}.months.${monthIndex}.value`,
                String(monthItem.value).replace('.', ','),
              );
            }
          });
        },
      );
    }
  }, [setValue, draftNewItem]);

  const handleAddService = () => {
    const initialAppend = {
      serviceId: '',
      readjustmentType: '',
      months: [{ value: '' }],
    };

    append(initialAppend);
  };

  const handleRemoveService = (serviceIndex) => {
    remove(serviceIndex);
  };

  const serviceSelectOptions = useMemo(() => {
    return services?.map((service) => {
      return {
        label: service.description,
        value: service.id,
      };
    });
  }, [services]);

  const months = Array.from({ length: 12 }, (_, index) => {
    return new Date(0, index).toLocaleString('pt-BR', { month: 'long' });
  });

  return (
    <S.ExpandedAccordion title="Serviços" open>
      <S.ServiceList>
        {fields.map((field, index) => {
          return (
            <S.ServiceField key={field.id}>
              <S.ServiceFieldHead>
                <div>
                  <Controller
                    control={control}
                    name={`services.${index}.serviceId`}
                    render={({ onChange, value }) => (
                      <InputSelect
                        value={value}
                        options={serviceSelectOptions}
                        onChange={onChange}
                        placeholder="Selecione um serviço"
                        width="300px"
                        status={errors.services?.[index]?.serviceId && 'error'}
                        statusMessage={
                          errors.services?.[index]?.serviceId &&
                          'Campo requerido'
                        }
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`services.${index}.dieselReadjustment`}
                    defaultValue={false}
                    render={({ onChange, value }) => (
                      <Checkbox
                        value={value}
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                        label="Reajuste Diesel"
                        id={`diesel-readjustment-service-${index}}`}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`services.${index}.inflationReadjustment`}
                    defaultValue={false}
                    render={({ onChange, value }) => (
                      <Checkbox
                        value={value}
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                        label="Reajuste Inflação"
                        id={`inflation-readjustment-service-${index}}`}
                      />
                    )}
                  />
                </div>

                <S.DeleteButton
                  icon={TrashIcon}
                  onClick={() => handleRemoveService(index)}
                />
              </S.ServiceFieldHead>

              <div>
                {isCurvedFare ? (
                  <S.ServicesGrid>
                    {months.map((month, monthIndex) => {
                      return (
                        <Controller
                          control={control}
                          name={`services.${index}.months.${monthIndex}.value`}
                          render={({ onChange, value }) => (
                            <Input
                              value={value}
                              onChange={onChange}
                              label={utility.capitalizeString(month)}
                              prefix="R$"
                              required
                              data-testid={`service-${index}-month-${monthIndex}-value`}
                              onInput={(event) =>
                                handleInputMask(event, 'currency')
                              }
                              status={
                                errors.services?.[index]?.months?.[monthIndex]
                                  ?.value && 'error'
                              }
                            />
                          )}
                        />
                      );
                    })}
                  </S.ServicesGrid>
                ) : (
                  <Controller
                    control={control}
                    name={`services.${index}.months.${0}.value`}
                    render={({ onChange, value }) => (
                      <Input
                        value={value}
                        onChange={onChange}
                        prefix="R$"
                        required
                        onInput={(event) => handleInputMask(event, 'currency')}
                        width="200px"
                        data-testid={`service-${index}-month-0-value`}
                        status={
                          errors.services?.[index]?.months?.[0]?.value &&
                          'error'
                        }
                      />
                    )}
                  />
                )}
              </div>
            </S.ServiceField>
          );
        })}
      </S.ServiceList>

      <Flex justifyContent="flex-start" alignItems="center">
        <Button icon={AddIcon} name="Novo Serviço" onClick={handleAddService} />
      </Flex>
    </S.ExpandedAccordion>
  );
};

export default ServicesSection;
