import { Accordion, Button, InputDate, Tokens } from '@vli/locomotive-ds';
import styled from 'styled-components';

export const Spacing = styled.div`
  margin-top: ${Tokens.spacing.sm};
`;
export const SpacingMultselect = styled.div`
  margin-top: ${Tokens.spacing.sm};
  div {
    div {
      margin-bottom: 4px;
    }
  }
  ul li label label {
    width: 400px !important;
  }
`;

export const ExpandedAccordion = styled(Accordion)`
  width: 100%;
  margin-bottom: ${Tokens.spacing.sm};
`;

export const InputDateItem = styled(InputDate)`
  .react-date-picker__wrapper {
    width: 100%;
    min-width: 100% !important;

    svg {
      display: none;
    }
  }
`;

export const DeleteButton = styled(Button)`
  background: ${Tokens.colors.red300};

  svg {
    height: 20px;
  }
`;

export const ServiceList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  margin-bottom: ${Tokens.spacing.sm};
`;

export const ServiceField = styled.div`
  padding: ${Tokens.spacing.sm};
  border: 1px solid ${Tokens.colors.gray100};
  border-radius: 4px;
`;

export const ServiceFieldHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  margin-bottom: ${Tokens.spacing.xs};

  & > div {
    display: flex;
    gap: 16px;
  }
`;

export const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 8px;
`;
