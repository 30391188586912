import { Button } from '@vli/locomotive-ds/dist';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'components/core/modal';
import {
  ApproveTypes,
  FareType,
  getGroupedRailwayItemsId,
  getRailwayItems,
} from 'lib/contexts/contracts';
import * as utility from 'lib/core/utility';
import { ContractDetailActions } from 'store/contractDetail/contractDetail.ducks';

const Approve = ({ id }) => {
  const dispatch = useDispatch();
  const { contractDetails, priceActions } = useSelector(
    ({ contractDetail }) => contractDetail,
  );
  const closeModal = () => {
    dispatch(
      ContractDetailActions.setPriceAction({
        action: null,
        modal: false,
        id: null,
        item: {},
      }),
    );
  };

  const callback = () => {
    const viewScenario = contractDetails.filter.viewScenario || 'CurrentPrice';
    dispatch(
      ContractDetailActions.getContractDetail({
        id,
        viewScenario,
      }),
    );
  };

  const submit = () => {
    let data = [];
    let rows = [];
    let ids = [];

    switch (priceActions.item) {
      case 'all':
        if (contractDetails.details.fareType === FareType.FIXED_TARIFF) {
          rows = contractDetails.details.railwayItems;
        } else {
          rows = getRailwayItems(contractDetails.details.railwayItems);
        }

        data = {
          ...utility.formatParamsAction(rows, null),
          approveType: ApproveTypes.Mass,
        };

        break;

      case 'grouped':
        ids = getGroupedRailwayItemsId(priceActions.items);
        data = {
          idHistorys: ids,
          approveType: ApproveTypes.Grouped,
        };

        break;

      case 'grouped-all':
        ids = priceActions.items
          .map((item) => getGroupedRailwayItemsId(item))
          .flat();

        data = {
          idHistorys: ids,
          approveType: ApproveTypes.Mass,
        };

        break;

      default:
        data = {
          ...utility.formatParamsAction([priceActions.item], null),
          approveType: ApproveTypes.Individual,
        };
        break;
    }

    dispatch(ContractDetailActions.approvePrice(data, callback));
    closeModal();
  };

  const actions = () => (
    <>
      <Button
        variant="secondary"
        name="NÃO"
        onClick={() => {
          closeModal();
        }}
      />

      <Button variant="primary" name="SIM" onClick={submit} />
    </>
  );

  const setModalMessage = () => {
    let variableMessage = '';

    switch (priceActions.item) {
      case 'all':
        variableMessage = ` todos os preços`;
        break;
      case 'grouped-all':
        variableMessage = ` todos os preços`;
        break;
      case 'grouped':
        variableMessage = ` o preço dos itens ${utility.joinComma(
          priceActions.items.railwayItemCodeGrouping,
        )}`;
        break;

      default:
        variableMessage = ` o preço do item ${priceActions.item.railwayItemCode}`;
        break;
    }

    return `Tem certeza que deseja aprovar${variableMessage}?`;
  };

  return (
    <Modal
      className="modal"
      title="Aprovar preço"
      onClose={closeModal}
      actions={actions}
    >
      <div>{setModalMessage()}</div>

      {(priceActions.item === 'all' || priceActions.item === 'grouped-all') && (
        <p>Para ações em massa não é possível desfazer a ação.</p>
      )}
    </Modal>
  );
};
Approve.defaultProps = {
  id: '',
};
Approve.propTypes = {
  id: PropTypes.string,
};
export default Approve;
