import {
  CONTRACT_ADD_SERVICES,
  CONTRACTS,
  CONTRACTS_RECIPIENTS_BY_USER,
  DOWNLOAD_SPREADSHEET,
  REPORT_CONTRACT,
} from 'constants/endpoints';
import { DELETE, GET, POST, PUT } from 'constants/verbs';
import { put, takeLatest } from 'redux-saga/effects';

import api from 'services/api';

import { getReportNameBasedOnType } from 'lib/contexts/contracts';
import * as utility from 'lib/core/utility';

import { MessagesActions } from 'store/messages/messages.ducks';

import { ContractActions, ContractTypes } from './contract.ducks';

export function* listContract({ params }) {
  try {
    const response = yield api({
      method: GET,
      url: `${CONTRACTS}/${params}`,
    });
    yield put(ContractActions.listContractSuccess(response.data.data));
  } catch (error) {
    yield put(ContractActions.contractRequestFailure(error));
  }
}

export function* exportContract({ params }) {
  const { row, type } = params;

  try {
    const response = yield api({
      method: GET,
      url: `${REPORT_CONTRACT}/${type}?ContractId=${row.id}`,
      responseType: 'arraybuffer',
    });

    yield put(ContractActions.exportContractSuccess(response.data.data));
    utility.downloadFile(
      response.data,
      `${getReportNameBasedOnType(type, row)}.xlsx`,
    );
  } catch (error) {
    yield put(ContractActions.contractRequestFailure(error));
  }
}

export function* updateContract({ payload, callback }) {
  try {
    yield api({
      method: PUT,
      url: CONTRACTS,
      data: payload,
    });

    yield put(ContractActions.updateContractSuccess());

    yield put(
      MessagesActions.open({
        title: 'Editado com sucesso',
        type: 'success',
        contentText: `O contrato ${payload.code} foi editado com sucesso!`,
      }),
    );
    yield callback();
  } catch (error) {
    let message = 'Erro ao editar contrato!';
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }
    yield put(
      MessagesActions.open({
        title: 'Erro',
        type: 'error',
        contentText: message,
      }),
    );
    yield put(ContractActions.contractRequestFailure(error));
  }
}

export function* exportDieselReport({ params }) {
  const { row, type, formParams } = params;
  try {
    const response = yield api({
      method: GET,
      url: `${REPORT_CONTRACT}/diesel${formParams}`,
      responseType: 'arraybuffer',
    });

    yield put(ContractActions.exportDieselReportSuccess());
    utility.downloadFile(
      response.data,
      `${getReportNameBasedOnType(type, row)}.xlsx`,
    );
  } catch (error) {
    yield put(ContractActions.contractRequestFailure(error));
  }
}

export function* deleteContract({ params, callback }) {
  try {
    yield api({
      method: DELETE,
      url: `${CONTRACTS}/${params}`,
    });

    yield put(ContractActions.deleteContractSuccess());

    yield put(
      MessagesActions.open({
        title: 'Deletado com sucesso',
        type: 'success',
        contentText: `O contrato foi deletado com sucesso!`,
      }),
    );

    yield callback();
  } catch (error) {
    let message = 'Erro ao deletar contrato!';
    if (error.response?.data?.errors) {
      message = error.response.data.errors;
    }

    yield put(
      MessagesActions.open({
        title: 'Erro',
        type: 'error',
        contentText: message,
      }),
    );

    yield put(ContractActions.contractRequestFailure(error));
  }
}

export function* closeContract({ payload, callback }) {
  try {
    yield api({
      method: PUT,
      url: `${CONTRACTS}/close`,
      data: payload,
    });

    yield put(ContractActions.closeContractSuccess());

    yield put(
      MessagesActions.open({
        title: 'Contrato encerrado',
        type: 'success',
        contentText: `Um email informativo será enviado aos usuários relacionados`,
      }),
    );

    yield callback();
  } catch (error) {
    let message = 'Erro ao encerrar contrato!';
    if (error.response?.data?.errors) {
      message = error.response.data.errors;
    }

    yield put(
      MessagesActions.open({
        title: 'Erro',
        type: 'error',
        contentText: message,
      }),
    );

    yield put(ContractActions.contractRequestFailure(error));
  }
}

export function* downloadSpreadsheet({ params }) {
  const { contractId, filename } = params;
  try {
    const response = yield api({
      method: GET,
      url: `${DOWNLOAD_SPREADSHEET}/${contractId}`,
      responseType: 'arraybuffer',
    });
    yield utility.downloadFile(response.data, filename);
    yield put(ContractActions.downloadSpreadsheetSuccess());
  } catch (error) {
    let message = 'Falha ao baixar a planilha';
    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }
    yield put(
      MessagesActions.open({
        title: 'Falha no download',
        type: 'error',
        contentText: message,
      }),
    );
    yield put(ContractActions.contractRequestFailure(error));
  }
}

export function* listContractRecipientsByUserId({ userId }) {
  try {
    const response = yield api({
      method: GET,
      url: CONTRACTS_RECIPIENTS_BY_USER(userId),
    });

    yield put(
      ContractActions.listContractRecipientsByUserIdSuccess(response.data.data),
    );
  } catch (error) {
    yield put(ContractActions.listContractRecipientsByUserIdFailure(error));
  }
}

export function* addServices({ payload, callback }) {
  try {
    yield api({
      method: POST,
      url: CONTRACT_ADD_SERVICES,
      data: payload,
    });

    yield put(ContractActions.addServicesSuccess());

    yield put(
      MessagesActions.open({
        title: 'Envio confirmado',
        type: 'success',
        contentText: `Sucesso ao adicionar os serviços!`,
      }),
    );

    yield callback();
  } catch (error) {
    let message = 'Erro ao adicionar os serviços!';

    if (error.response.data?.errors) {
      message = error.response.data.errors;
    }

    yield put(
      MessagesActions.openModal({
        titleModal: 'Ocorreu um erro ao enviar',
        typeModal: 'error',
        contentModalText: utility.formatMessages([message]),
      }),
    );

    yield put(ContractActions.addServicesFailure(error));
  }
}

export function* watchSagas() {
  yield takeLatest(ContractTypes.LIST_CONTRACT, listContract);
  yield takeLatest(ContractTypes.EXPORT_CONTRACT, exportContract);
  yield takeLatest(ContractTypes.EXPORT_DIESEL_REPORT, exportDieselReport);
  yield takeLatest(ContractTypes.UPDATE_CONTRACT, updateContract);
  yield takeLatest(ContractTypes.DELETE_CONTRACT, deleteContract);
  yield takeLatest(ContractTypes.CLOSE_CONTRACT, closeContract);
  yield takeLatest(ContractTypes.DOWNLOAD_SPREADSHEET, downloadSpreadsheet);
  yield takeLatest(
    ContractTypes.LIST_CONTRACT_RECIPIENTS_BY_USER_ID,
    listContractRecipientsByUserId,
  );
  yield takeLatest(ContractTypes.ADD_SERVICES, addServices);
}
