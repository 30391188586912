import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { NewItemsActions } from 'store/newItems/newItems.ducks';

import ModalConfirm from 'components/core/ModalConfirm';

const ConfirmModal = ({ handleSaveDraft }) => {
  const dispatch = useDispatch();

  const { handleSubmit, getValues } = useFormContext();

  return (
    <ModalConfirm
      closeModal={() =>
        dispatch(
          NewItemsActions.setModalOpen({
            modalOpen: false,
          }),
        )
      }
      confirmAction={handleSubmit((e) => {
        handleSaveDraft(e, 1);
      })}
      title={`Deseja vincular o item ferroviário ${getValues(
        'railwayId',
      )} ao contrato ${getValues('contract')}?`}
      message="Ao vincular, o item estará presente na listagem de itens ferroviários do contrato com possibilidade de aprovação e reprovação de preços futuros"
    />
  );
};

export default ConfirmModal;
