export const formDefaultValues = {
  recommendedPrice: [
    {
      endDate: null,
      movementDate: null,
      product: '',
      modal: '',
      client: '',
      origin: '',
      destiny: '',
      state: '',
      city: '',
      plant: '',
      mesoRegion: '',
      tariff: 0,
    },
  ],
};

export const productOptions = [
  { value: 'Ferro', label: 'Ferro' },
  { value: 'Soja', label: 'Soja' },
  { value: 'Milho', label: 'Milho' },
];

export const modalOptions = [
  { value: 1, label: 'Ferrovia' },
  { value: 2, label: 'Porto' },
];

export const clientOptions = [
  { value: 1, label: 'Vale' },
  { value: 2, label: 'Usiminas' },
  { value: 3, label: 'Vibra' },
  { value: 4, label: 'Selecta' },
  { value: 5, label: 'Gelf' },
];

export const originOptions = [
  { value: 1, label: 'VPN' },
  { value: 2, label: 'VTU' },
  { value: 3, label: 'VFU' },
  { value: 4, label: 'EPM' },
  { value: 5, label: 'VIC' },
  { value: 6, label: 'TPD' },
  { value: 7, label: 'TUF' },
];

export const destinyOptions = [
  { value: 1, label: 'VPN' },
  { value: 2, label: 'VTU' },
  { value: 3, label: 'VFU' },
  { value: 4, label: 'EPM' },
  { value: 5, label: 'VIC' },
];

export const stateOptions = [
  { value: 'MG', label: 'MG' },
  { value: 'BA', label: 'BA' },
  { value: 'GO', label: 'GO' },
];

export const cityOptions = [
  { value: 'Cidade 1', label: 'Cidade 1' },
  { value: 'Cidade 2', label: 'Cidade 2' },
  { value: 'Cidade 3', label: 'Cidade 3' },
];

export const plantOptions = [
  { value: 'Usina 1', label: 'Usina 1' },
  { value: 'Usina 2', label: 'Usina 2' },
  { value: 'Usina 3', label: 'Usina 3' },
];

export const mesoRegionOptions = [
  { value: 'Meso 1', label: 'Meso 1' },
  { value: 'Meso 2', label: 'Meso 2' },
  { value: 'Meso 3', label: 'Meso 3' },
];

export const errorMessages = {
  LIMIT: 'O arquivo excedeu o limite de 1500 linhas.',
  PROCESS: 'Ocorreu um erro ao processar o arquivo.',
  TYPE: 'Formato do arquivo não é válido.',
  FIELD: 'O arquivo não possui as colunas necessárias.',
};

export const ROW_LIMIT = 1500;

export const ALLOWED_FORMATS = [
  '.xlsx',
  '.xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];
