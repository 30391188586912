import { endOfMonth, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import PropTypes from 'prop-types';
import React from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import * as utility from 'lib/core/utility';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: payload[0].fill,
          padding: 12,
          border: '1px solid #b1b1b1',
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <p style={{ fontWeight: 'bold' }}>{label}</p>
        <p
          style={{ color: payload[0].color }}
        >{`${payload[0]?.dataKey}: ${payload[0]?.value}`}</p>
        <p
          style={{ color: payload[1].color }}
        >{`${payload[1]?.dataKey}: ${payload[1]?.value}`}</p>
        <p
          color={payload[0].stroke}
        >{`Contrato Vigentes: ${payload[0]?.payload.Total}`}</p>
      </div>
    );
  }

  return null;
};

const ReportChart = ({ data, handleFilter }) => {
  const dateFormatter = (date) => {
    return format(new Date(date), "MMMM 'de' yyyy", {
      locale: ptBR,
    });
  };

  const getChartData = (reportData) => {
    return reportData.map((reportItem) => {
      return {
        name: utility.capitalizeFirstString(dateFormatter(reportItem.period)),
        Incluídos: reportItem.added,
        Encerrados: reportItem.closed,
        Total: reportItem.total,
      };
    });
  };

  const handleLineClick = ({ payload }) => {
    const filterData = {
      StartDate: new Date(payload.period),
      EndDate: endOfMonth(new Date(payload.period)),
    };

    handleFilter(filterData);
  };

  return (
    <div data-testid="report-chart-test">
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={getChartData(data)}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Line
            dataKey="Incluídos"
            stroke="#38A740"
            strokeWidth={2}
            activeDot={{ onClick: handleLineClick }}
          />
          <Line
            dataKey="Encerrados"
            stroke="#E24445"
            strokeWidth={2}
            activeDot={{ onClick: handleLineClick }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

ReportChart.propTypes = {
  data: PropTypes.array.isRequired,
  handleFilter: PropTypes.func.isRequired,
};

export default ReportChart;
